import React, {useState} from "react";
import {Circle} from "react-circle";
import useInterval from "use-interval";
import "./countdown-circle.css"

interface Properties {
    duration: number
}

export const CountdownCircle = ({duration}: Properties) => {

    const [countdown, setCountdown] = useState(duration)

    const delay = 50;
    useInterval(() => {
        setCountdown(countdown - delay)
    }, delay)

    return <div className={"circle-of-life-container"}>
        <Circle
            progress={100 - countdown / (duration) * 100}
            showPercentageSymbol={false}
            showPercentage={false}
            responsive={false}  // change later
            size="25"
            bgColor="#00ab9a"
            progressColor="white"
        />
    </div>
}
