import React, {Component} from 'react'
// @ts-ignore
import IdleTimer from 'react-idle-timer'
import {isAuthenticated} from "../store/user/UserSelector";
import {connect} from "react-redux";
import {logout} from "../store/user/UserActions";
import {RootState} from "../store/root-reducer";

interface ComponentProps {
    timeoutSeconds: number;
}

interface Properties extends ComponentProps {
    isAuthenticated: boolean;

    logout(): void;
}

class IdleDetectorComponent extends Component<Properties> {

    handleOnIdle() {
        if (this.props.isAuthenticated) {
            this.props.logout();
        }
    }

    render() {
        return (
            <IdleTimer timeout={1000 * this.props.timeoutSeconds}
                       onIdle={() => this.handleOnIdle()}
                       debounce={250}
            />
        )
    }
}

const mapStateToProps = (state: RootState, ownProps: ComponentProps) => {
    return {
        isAuthenticated: isAuthenticated(state.user),
        ...ownProps
    }
};

export const IdleDetector = connect(mapStateToProps, {logout})(IdleDetectorComponent);
