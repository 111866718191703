function normalizePath(path: string): string {
    if (path !== "/" && path.endsWith("/")) {
        path = path.substring(0, path.length - 1);
    }

    const frags = path.split("/")
    let i = 0;
    while (i < frags.length) {
        if (i > 0 && frags[i] === "..") {
            frags.splice(i - 1, 2);
            i -= 2;
        } else if (frags[i][0] === ".") { // removes also three or more dots
            frags.splice(i, 1);
            i--;
        }
        i++;
    }

    if (frags.length === 1 && !frags[0]) {
        return "/";
    }
    return frags.join("/");
}

export function pathEquals(path1: string, path2: string): boolean {
    const normalizedPath1 = normalizePath(path1);
    const normalizedPath2 = normalizePath(path2);
    return normalizedPath1 === normalizedPath2;
}

export function containsStringInsensitive(arr: string[], str?: string) {
    if (str === undefined) {
        return false;
    }
    const lowerStr = str.toLowerCase();
    return arr.some(e => e.toLowerCase() === lowerStr);
}
