import React, {ChangeEvent, Component} from "react";
import {Redirect, RouteComponentProps, withRouter} from "react-router";
import {connect} from "react-redux";
import {Button, Col, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import {IoIosEye, IoIosEyeOff} from "react-icons/io";
import {login} from "../../store/user/UserActions";
import {RootState} from "../../store/root-reducer";
import {User} from "../../models/models";
import {AuthenticationDTO} from "../../models/dto";

import "./login.css"

interface ComponentProps {
    redirectPath: string;
    redirectToNonReact: boolean;
}

interface Properties extends ComponentProps, RouteComponentProps {
    login(user: User): void;
}

interface State {
    mail: string,
    password: string,
    redirect: boolean,
    alert?: string,
    showPassword: boolean,
    loginFailedNumber: number,
    isUiLocked: boolean
}

const maxRetriesWithoutPause = 3;
const retryDelay = 3000;
const cumulativeMaxRetryDelay = 10000;

class LoginComponent extends Component<Properties, State> {

    constructor(props: Properties) {
        super(props);

        this.state = {
            mail: "",
            password: "",
            redirect: false,
            alert: undefined,
            showPassword: false,
            loginFailedNumber: 0,
            isUiLocked: false
        };
    }

    validateForm() {
        return this.state.mail.length > 0 && this.state.password.length > 0;
    }

    handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            mail: event.currentTarget.value
        });
    };

    handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            password: event.currentTarget.value
        });
    };

    async loginAtBackend(user: string, password: string) {
        const response = await fetch("/jwt/authenticate", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: user,
                password: password
            })
        });
        if (!response.ok) {
            throw new Error(`Code ${response.status}: ${response.statusText}`);
        }
        return response.json();
    }

    handleAuthentication() {
        return this.loginAtBackend(this.state.mail.toLowerCase(), this.state.password)
            .then((json: AuthenticationDTO) => {
                // This order of calls is needed! Otherwise, we stay on /login
                this.setState({redirect: true}, () => {
                    this.props.login({
                        name: json.name,
                        permissions: json.permissions
                    })
                });
            })
            .catch(reason => {
                this.setState({alert: "Login Failed!"});
                throw reason;
            });
    }

    private delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({
            isUiLocked: true,   // lock ui
            alert: undefined    // clear error text
        }, () => {
            let basePromise;
            if (this.state.loginFailedNumber >= maxRetriesWithoutPause) {
                // max number of retries reached => wait before sending request
                const retryDiff = this.state.loginFailedNumber - maxRetriesWithoutPause;
                const cumulativeDelay = retryDelay + retryDiff * 1000;

                basePromise = this.delay(Math.min(cumulativeDelay, cumulativeMaxRetryDelay));
            } else {
                // max number of retries not reached => send request immediately
                basePromise = Promise.resolve();
            }

            basePromise
                .then(() => this.handleAuthentication())
                .then(() => {
                    // Login successful => unlock ui and reset counter
                    this.setState({
                        loginFailedNumber: 0,
                        isUiLocked: false
                    });
                })
                .catch(() => {
                    // Login failed => unlock ui and increment counter
                    this.setState(prev => ({
                        loginFailedNumber: prev.loginFailedNumber + 1,
                        isUiLocked: false
                    }));
                })
        });
    }

    renderAlert() {
        if (this.state.alert) {
            return <div className={"alert-button"}>
                {this.state.alert}
            </div>
        }
        return <></>;
    }

    render() {
        if (this.state.redirect) {
            if (this.props.redirectToNonReact) {
                window.location.href = this.props.redirectPath;
                return null;
            }
            return (<Redirect to={this.props.redirectPath}/>);
        }

        return (
            <div className="Login">
                <h1>Login</h1>
                <form onSubmit={this.handleSubmit}>
                    <FormGroup as={Row} controlId="mail">
                        <FormLabel className="login-label" column sm="3">Email</FormLabel>
                        <Col sm="7">
                            <FormControl className="login-form"
                                         autoFocus
                                         type="email"
                                         value={this.state.mail}
                                         disabled={this.state.isUiLocked}
                                         onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleEmailChange(e)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="password">
                        <FormLabel className="login-label" column sm="3">Password</FormLabel>
                        <Col sm="7">
                            <FormControl className="login-form"
                                         value={this.state.password}
                                         disabled={this.state.isUiLocked}
                                         onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePasswordChange(e)}
                                         type={this.state.showPassword ? "text" : "password"}
                            />

                            {this.renderShowPWButton()}
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                        <Col sm="10">
                            <Button id="login-button"
                                    block
                                    disabled={this.state.isUiLocked || !this.validateForm()}
                                    type="submit"
                            >
                                Login
                            </Button>
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                        <Col sm="10">
                            {this.renderAlert()}
                        </Col>
                    </FormGroup>
                </form>
            </div>
        );
    }

    renderShowPWButton() {
        const iconProps = {
            className: "show-password",
            onClick: () => this.setState({showPassword: !this.state.showPassword}),
            size: "2em",
            color: "grey"
        }
        if (!this.state.showPassword) {
            return <IoIosEye {...iconProps}/>
        } else {
            return <IoIosEyeOff {...iconProps}/>
        }
    }
}

const mapStateToProps = (_state: RootState, ownProps: ComponentProps) => {
    return {
        ...ownProps
    }
};

export const Login = connect(mapStateToProps, {login})(withRouter(LoginComponent));
