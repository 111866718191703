import * as userActions from './UserActions';
import {ADD_PERMISSIONS, LOGIN, LOGOUT} from "./UserConstants";
import {ActionType} from 'typesafe-actions';
import {combineReducers} from "redux";
import {User} from "../../models/models";

export type UserActions = ActionType<typeof userActions>;
export type UserState = {
    readonly user: User | null;
};

export default combineReducers<UserState, UserActions>({
    user: (state = null, action) => {
        switch (action.type) {
            case LOGIN:
                console.log("LOGIN")
                if (action.payload.user !== null) {
                    sessionStorage.setItem("master_class", JSON.stringify({user: {user: action.payload.user}}))
                    return action.payload.user;
                }
                return state;
            case LOGOUT:
                console.log("LOGOUT")

                sessionStorage.setItem("master_class", JSON.stringify({user: {user: null}}))
                logoutFromBackend();
                return null
            case ADD_PERMISSIONS:
                if (state && action.payload.permissions) {
                    return {
                        name: state.name,
                        permissions: action.payload.permissions
                    }
                } else {
                    console.log("LOGGING OUT")
                    return null
                }
            default:
                return state;
        }
    }
});

const logoutFromBackend = () => {
    fetch("/jwt/logout")
        .then(res => {
            if (!res.ok) {
                throw new Error(`Code ${res.status}: ${res.statusText}`);
            }
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => {
            return null
        });
}
