import {LiteralsState} from "./LiteralsReducer";
import {lang, SupportedLanguages} from "../../i18n/models";
import {LanguageItem} from "../../Components/Navigation/LanguageDropdown";

export const getLiterals = (state: LiteralsState) => state.literals;
export const getSummary = (state: LiteralsState) => state.literals.summary;
export const getWashStationDetails = (state: LiteralsState) => state.literals.washStationDetails;
export const getWelcome = (state: LiteralsState) => state.literals.welcome
export const getFooter = (state: LiteralsState) => state.literals.footer
export const getNavBar = (state: LiteralsState) => state.literals.navBar
export const getCurrentLanguage = (state: LiteralsState) => state.literals.lang
export const getSupportedLanguages = (): LanguageItem[] => {
    return (Object.entries(lang).map(
        ([tag, value]) => {
            const languageTag = tag as SupportedLanguages;
            return {tag: languageTag, lang: value.lang}
        }
    ))
}
