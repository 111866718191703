import React from "react";
import Hyphenated from "react-hyphen";
import de from 'hyphenated-de';
import {object} from "prop-types";
import {PayedMachineInfoArrayType} from "../../models/dto";
import {LocationType} from "../../models/models";
import {getFullDisplayLabel} from "../../utils/ZvtUtils";

export function moneyFormatter(cell: number) {
    //insert Komma and €
    const moneyString = `${(cell / 100).toFixed(2)}€`.replace(".", ",");
    if (moneyString === "NaN€") {
        return "";
    }
    return insertReadabilityPoints(moneyString);
}

export function productFormatter(locationType: LocationType) {
    return (cell: PayedMachineInfoArrayType) => {
        // Transforms data from MC to readable product configuration [null,{amount:1},{amount:2}] to 2: 1x, 3: 2x

        let lineBreak = false;
        const result = cell
            .map((choice, index) => {
                if (choice === null || choice.amount <= 0) {
                    return "";
                }

                if (choice.type === undefined && choice.label === undefined) {
                    return `${index + 1}: ${choice.amount}x`; // GWA
                }

                // WS
                lineBreak = true;
                return `${getFullDisplayLabel(choice.type, choice.label)}: ${choice.amount}x`;
            })
            .filter(choice => choice !== "");

        if (result.length === 0) {
            return "nichts gewählt";
        }

        if (locationType === LocationType.LAUNDROMAT && lineBreak) {
            return <>
                {result.flatMap((value, index, array) => {
                    if (index === array.length - 1) {
                        return value;
                    }
                    return [value, <br key={`TableFormatter_ProductFormatter_Row${index}`}/>]
                })}
            </>
        }
        return result.join(", ");
    };
}

function insertReadabilityPoints(moneyString: string) {
    let len = moneyString.length - 7;
    while (len > 0) {
        moneyString = `${moneyString.slice(0, len)}.${moneyString.slice(len)}`;
        len -= 3;
    }
    return moneyString;
}

export function hyphenationFormatter(content: any) {
    if (content === null || content === undefined) {
        return <></>;
    }

    if (typeof content === 'string' || content instanceof String) {
        return <Hyphenated language={de}>
            {content}
        </Hyphenated>
    } else if (typeof content === "object" || content instanceof object) {
        return <Hyphenated language={de}>
            {content.text ?? ""}
        </Hyphenated>
    }
    return <></>;
}

function toLocalDay(date: Date) {
    return Math.floor((date.valueOf() - date.getTimezoneOffset() * 60000) / 8.64e7);
}

function isValidDate(d: any): d is Date {
    return d instanceof Date && !isNaN(d.getTime());
}

export function timestampFormatter(cell: any, row?: any, rowIndex?: number, formatExtraData?: any, timeZone?: any): string {
    let dateObj = cell;
    if (!dateObj) {
        return "-";
    }
    if (typeof cell !== 'object') {
        dateObj = new Date(cell);
    }

    if (!isValidDate(dateObj)) {
        return "Nicht verfügbar";
    }

    const now = new Date();
    const epochDayNow = toLocalDay(now);
    const epochDayDate = toLocalDay(dateObj);

    const timeFormat: Intl.DateTimeFormatOptions = {
        timeZone: timeZone,
        hourCycle: "h23", // "hour12: false" is not sufficient for some locales; fixes times like 24:00
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    };

    switch (epochDayNow) {
        case epochDayDate:
            return `Heute, ${dateObj.toLocaleString(undefined, timeFormat)}`;
        case epochDayDate + 1:
            return `Gestern, ${dateObj.toLocaleString(undefined, timeFormat)}`;
        default: {
            const dateFormat: Intl.DateTimeFormatOptions = {
                ...timeFormat,
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            }
            return dateObj.toLocaleString(undefined, dateFormat);
        }
    }
}
