import {Component} from 'react';
import {RouteComponentProps, StaticContext} from "react-router";
import {Location, UnregisterCallback} from "history";

type HistoryPropType<T> = RouteComponentProps<{}, StaticContext, T>;

export default abstract class AbstractHistoryView<T> extends Component<HistoryPropType<T>, T> {

    private unregisterCallback?: UnregisterCallback

    constructor(props: HistoryPropType<T>) {
        super(props)

        this.state = this.getDefaultHistoryLocationState();
    }

    abstract getDefaultHistoryLocationState(): Readonly<T>;

    abstract isHistoryLocationState(object: any): object is T;

    private push(pathname: string, state: T) {
        this.props.history.push({
            pathname: pathname,
            state: state
        })
    }

    pushToHistory<K extends keyof T>(componentState: Pick<T, K> | T | null, target: string, historyStateProducer: () => T) {
        // Use a producer because if we pass the state directly, we will use the old version in the callback
        this.setState(componentState, () => this.push(target, historyStateProducer()));
    }

    componentDidMount() {
        const stateFromHistory = this.props.history.location.state;
        if (this.isHistoryLocationState(stateFromHistory)) {
            this.setState(stateFromHistory);
        }

        this.unregisterCallback = this.props.history.listen((location: Location) => {
            const state = location.state;
            if (this.isHistoryLocationState(state)) {
                this.setState(state);
            } else {
                this.setState(this.getDefaultHistoryLocationState());
            }
        });
    }

    componentWillUnmount() {
        if (this.unregisterCallback) {
            this.unregisterCallback()
        }
    }
}
