import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import {Nav, Navbar} from "react-bootstrap";
import {connect} from "react-redux";
import {RootState} from "../../store/root-reducer";
import {getFooter} from "../../store/literals/LiteralsSelector";
import {FooterLiterals} from "../../i18n/models";

import "./Footer.scss";

interface Properties {
    footerLiterals: FooterLiterals;
    userName?: string
}

class FooterComponent extends Component<Properties> {
    createNavLink(to: string, text: string) {
        return <NavLink to={to} className={"nav-link"}>{text}</NavLink>;
    }

    createUserText() {
        // Keep this element even if userName is undefined!
        return <div className="footer-mail">{this.props.userName}</div>
    }

    render() {
        return (
            <div className="footer">
                <Navbar bg="light" expand="lg">
                    {this.createUserText()}
                    <Navbar id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            {this.createNavLink("/privacypolicy", this.props.footerLiterals.privacy)}
                            {this.createNavLink("/legal", this.props.footerLiterals.legal)}
                        </Nav>
                    </Navbar>
                </Navbar>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        footerLiterals: getFooter(state.literals),
        userName: state.user.user?.name
    }
}

export const Footer = connect(mapStateToProps)(FooterComponent);
