import {toWtgTypeShort, WtgType, WtgTypeShort} from "../models/models";

function getShortDisplayLabel(type?: WtgType, label?: string) {
    if (type === undefined || label === undefined) {
        return undefined;
    }

    if (type === WtgType.WASCHMITTELAUSGABE) {
        return undefined;
    }

    const words = label.split(" ");
    if (words.length > 1) {
        return words[words.length - 1];
    }
    return undefined;
}

export function getWtgLabels(type?: WtgType, label?: string) {
    const shortLabel = getShortDisplayLabel(type, label);
    if (label !== undefined && type !== undefined) {
        const words = label.split(" ");
        const firstWord = words[0].toLowerCase();
        const lastWord = words[words.length - 1].toLowerCase();

        let startIndex = 0;
        if (firstWord === type.toLowerCase() || firstWord === toWtgTypeShort(type)?.toLowerCase()) {
            startIndex = 1;
        }

        let endIndex = words.length;
        if (shortLabel?.toLowerCase() === lastWord) {
            endIndex--;
        }

        label = words.slice(startIndex, endIndex).join(" ");
    }
    return {label, shortLabel};
}

export function getFullDisplayLabel(type?: WtgType, label?: string) {
    if (type === undefined || label === undefined) {
        return undefined;
    }

    if (type === WtgType.WASCHMITTELAUSGABE) {
        return WtgTypeShort.WASCHMITTELAUSGABE;
    }

    const words = label.split(" ");
    const lastWord = words[words.length - 1];
    if (type == WtgType.WASCHMASCHINE) {
        return `${WtgTypeShort.WASCHMASCHINE} ${lastWord}`;
    }
    if (type == WtgType.TROCKNER) {
        return `${WtgTypeShort.TROCKNER} ${lastWord}`;
    }
    return `${type} ${lastWord}`; // Fallback
}
