import * as config from './HeartbeatActions';
import {CLEAR_INTERVAL, SET_INTERVAL} from "./HeartbeatConstants";
import {ActionType} from 'typesafe-actions';
import {combineReducers} from "redux";

export type HeartbeatActions = ActionType<typeof config>;
export type HeartbeatIntervalState = {
    readonly heartbeatInterval: HeartbeatInterval | null;
};

export interface HeartbeatInterval {
    endTimestamp: Date,
    description: string
}

export default combineReducers<HeartbeatIntervalState, HeartbeatActions>({
        heartbeatInterval: (state = null, action) => {
            if (action.type === SET_INTERVAL) {
                if (state === null) {  // wenn ein state existiert soll nichts geschehen
                    const {endTimestamp, description} = action.payload;
                    return {endTimestamp, description};
                }
            } else if (action.type === CLEAR_INTERVAL) {
                return null;
            }
            return state;
        }
    }
);
