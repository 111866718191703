import React, {lazy} from "react";
import {PermissionCheck, RouteConfig, UserPermission} from "../models/models";
import Home from "../views/Home" // Home contains only a switch between Summary and WashStationDetails => Don't load lazy!

const SystemConfigurationView = lazy(() => import("../views/SystemConfigurationView"));

const {
    ROLE_ADMIN,    // Kann alles
    ROLE_ECO,      // Kann alles außer Benutzer editieren
    ROLE_OPERATOR, // Sieht und steuert eigene GWAs

    SHOW_ALL_GWAS, // Hauptseite, unnötig fürs FE
    SHOW_OWN_GWAS, // Hauptseite, unnötig fürs FE
    EDIT_GWAS,     // Kontakte, Standort, BetreiberID
    EDIT_DEVICES,  // Kontroll Panel, wie implementiert
    EDIT_USERS,    // User hinzufügen, löschen
    EDIT_PRICES,   // Ist klar
    EDIT_MCS,      // Vllt später
    START_OTA,     // Updatefunktion
    CONTROL_DEVICES
} = UserPermission;

const routeConfigs: RouteConfig[] = [
    {
        path: "/system-configuration",
        requiredPermissions: [ROLE_ADMIN, ROLE_ECO, ROLE_OPERATOR],
        permissionCheck: PermissionCheck.ANY,
        onNavbar: true,
        component: <SystemConfigurationView/>,
        label: "Stammdatenverwaltung",
        backRoute: {
            path: "/",
            label: "Betriebsdaten"
        }
    }, {
        path: "/",
        requiredPermissions: [SHOW_ALL_GWAS, SHOW_OWN_GWAS, EDIT_GWAS, EDIT_DEVICES, EDIT_USERS,
            EDIT_PRICES, EDIT_MCS, START_OTA, CONTROL_DEVICES],
        permissionCheck: PermissionCheck.ANY,
        onNavbar: false,
        component: <Home/>
    }
]

export default function getRouteConfigs() {
    return routeConfigs;
}
