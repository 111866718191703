import * as config from './ConfigActions';
import {CHANGE_DOWNLOAD_SYSTEM_LOG_INTERVAL} from "./ConfigConstants";
import {ActionType} from 'typesafe-actions';
import {combineReducers} from "redux";

export type ConfigActions = ActionType<typeof config>;
export type ConfigState = {
    readonly config: Config;
};

export interface Config {
    downloadSystemLogInterval: number
}

export default combineReducers<ConfigState, ConfigActions>({
        config: (state = {downloadSystemLogInterval: 40}, action) => {
            if (action.type === CHANGE_DOWNLOAD_SYSTEM_LOG_INTERVAL) {
                const stateClone = {...state};
                stateClone.downloadSystemLogInterval = action.payload;
                return stateClone;
            }
            return state;
        }
    }
);
