import * as literals from './LiteralsActions';
import {CHANGE_LITERALS} from "./LiteralsConstants";
import {ActionType} from 'typesafe-actions';
import {lang, Literals} from "../../i18n/models";
import {combineReducers} from "redux";

export type LiteralsAction = ActionType<typeof literals>;
export type LiteralsState = {
    readonly literals: Literals;
};

export default combineReducers<LiteralsState, LiteralsAction>({
    literals: (state = lang.de, action) => {
        if (action.type === CHANGE_LITERALS) {
            return lang[action.payload];
        }
        return state;
    }
});
