import React, {Component, lazy, Suspense} from 'react';
import {RouteComponentProps} from "react-router";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {RootState} from "../store/root-reducer";
import {logout} from "../store/user/UserActions";
import {getUser} from "../store/user/UserSelector";
import getRouteConfigs from "../data/getRouteConfigs";
import {Login} from "./Authentication/Login";
import {User} from "../models/models";
import {hasPermission} from "./Navigation/NavBar";
import {FetchError, refreshFetch} from "../utils/RefreshFetch";
import {isDeployedToKubernetes} from "../utils/EnvironmentUtils";

const Impressum = lazy(() => import("../views/Impressum"));
const Datenschutz = lazy(() => import("../views/Datenschutz"));
const NotFound = lazy(() => import("../views/NotFound"));

interface Properties extends RouteComponentProps {
    logout(): void;

    user: User | null;
}

interface State {
    startRendering: boolean
}

class ContentRouter extends Component<Properties, State> {

    onLoginRedirect: string;
    redirectToReferrer: boolean;
    alreadyLoggedIn: boolean;

    constructor(props: Properties) {
        super(props);

        this.state = {
            startRendering: false
        }

        this.onLoginRedirect = "/";
        this.redirectToReferrer = false;
        this.alreadyLoggedIn = props.user !== null;

        const originalQueryParams = window.location.search;
        if (originalQueryParams) {
            const urlParams = new URLSearchParams(originalQueryParams);
            const referrer = urlParams.get('referrer');
            if (referrer) {
                this.onLoginRedirect = referrer;
                this.redirectToReferrer = true;
            }
        }
    }

    componentDidMount() {
        if (this.props.user) {
            // Check if login is still active
            this.isStillLoggedIn().then(isLoggedIn => {
                if (!isLoggedIn) {
                    // user in Redux set but not logged in into server => delete Redux-user
                    this.props.logout();
                }
            }).finally(() => {
                // user in Redux consistent with login state
                this.setState({startRendering: true});
            });
        } else {
            // user in Redux not set => show login form
            this.setState({startRendering: true});
        }
    }

    async isStillLoggedIn(): Promise<boolean> {
        try {
            await refreshFetch("/config/frontend", {
                credentials: "same-origin"
            });
        } catch (error) {
            if (error instanceof FetchError) {
                return error.response.status !== 401;
            }
        }
        return true;
    }

    getLoggedOutRoutes() {
        return [
            <Route key={"routeKey_logged_out_root"} exact path="/" render={() => {
                return <Redirect to="/login"/>
            }}/>,
            <Route key={"routeKey_logged_out_login"} exact path="/login" component={() => {
                return <Login
                    redirectPath={this.onLoginRedirect}
                    redirectToNonReact={this.redirectToReferrer}
                />
            }}/>,

            /* Any other route */
            <Route key={"routeKey_logged_out_any"} render={() => {
                return <Redirect to="/login"/>
            }}/>
        ]
    }

    getRoutesFromConfig() {
        if (!this.props.user || this.redirectToReferrer) {
            return this.getLoggedOutRoutes()
        } else {
            const permissionsGranted = this.props.user.permissions
            const routes = getRouteConfigs().filter((route) => hasPermission(
                route.requiredPermissions, permissionsGranted, route.permissionCheck
            ));
            return routes.map((route) =>
                <Route key={`routeKey_${route.path}`} path={route.path} render={() => {
                    return route.component
                }}/>)
        }
    }

    getFooterRoutes() {
        if (isDeployedToKubernetes() && (!this.props.user || this.redirectToReferrer)) {
            return [];
        }

        return [
            <Route key={"routeKey_legal"} path="/legal" component={Impressum}/>,
            <Route key={"routeKey_privacypolicy"} path="/privacypolicy" component={Datenschutz}/>
        ];
    }

    render() {
        if (!this.state.startRendering) {
            // Prevent rendering while checking for login
            return null;
        }

        if (this.alreadyLoggedIn && this.redirectToReferrer) {
            window.location.href = this.onLoginRedirect;
            return null;
        }

        return (
            <div className="content">
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        {this.getFooterRoutes()}
                        {this.getRoutesFromConfig()}
                        <Route component={NotFound}/>
                    </Switch>
                </Suspense>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        user: getUser(state.user)
    }
};

export default connect(mapStateToProps, {logout})(withRouter(ContentRouter))
