import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Route, Router, Switch} from "react-router-dom";
import Hyphenated from "react-hyphen";
import de from 'hyphenated-de';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {isDeployedToKubernetes} from "./utils/EnvironmentUtils";
import {refreshFetch} from "./utils/RefreshFetch";
import {NavBar} from "./Components/Navigation/NavBar";
import {Footer} from "./Components/Navigation/Footer"
import ContentRouter from "./Components/ContentRouter";
import Notifications from "./Components/Overlays/Notifications";
import history from "./history";
import {HeartbeatNotification} from "./Components/Overlays/HeartbeatNotification";
import {IdleDetector} from "./Components/IdleDetector";

import "./Components/material-ui.scss"
import "./Components/Overlays/Hover/hover.scss"
import "./App.scss";

interface PageProperties {
    title: string
}

const Page: React.FC<React.PropsWithChildren<PageProperties>> = (props) => {
    useEffect(() => {
        document.title = props.title;
    }, [props.title]);
    return <>{props.children}</>;
};

const shortTitle = "Management System";
const fullTitle = document.title || `EcoExpress ${shortTitle}`;
const autoLogoutMinutes = 30;

// Enables DayJS to parse formats like "H:mm".
dayjs.extend(customParseFormat);

export const App = () => {

    const [backupMode, setBackupMode] = useState(false);

    useLayoutEffect(() => {
        refreshFetch("/config/is-backup")
            .then(res => res.json())
            .then((res: boolean) => setBackupMode(res))
            .catch(error => console.error(error));
    }, []);

    const hideIfLoggedOutOnKubernetes = (component: JSX.Element) => {
        return isDeployedToKubernetes() ?
            <Switch>
                <Route exact path="/login" render={() => <Page title={shortTitle}/>}/>
                <Route render={() => <Page title={fullTitle}>{component}</Page>}/>
            </Switch> :
            component;
    };

    return (
        <div className="App">
            <Router history={history}>
                <IdleDetector timeoutSeconds={autoLogoutMinutes * 60}/>
                <Hyphenated language={de}>
                    <Notifications/>
                    <HeartbeatNotification/>
                    {hideIfLoggedOutOnKubernetes(<NavBar backupMode={backupMode}/>)}
                    <ContentRouter/>
                    {hideIfLoggedOutOnKubernetes(<Footer/>)}
                </Hyphenated>
            </Router>
        </div>
    );
}
