import React from "react";
import dayjs from "dayjs";

export interface Contact {
    id?: number,
    familyName: string,
    givenName: string,
    mail: string,
    emailEnabled: boolean,
    mobile: string,
    telephone: string,
    comment: string,
    role: string,
    enabled: boolean
}

export interface LogEntry {
    "sys_time": string,
    "sys_date": string,
    "result_code": string,
    "amount": string,
    "trace": string,
    "orig_trace": string,
    "pt_time": string,
    "pt_date": string,
    "expiry_date": string,
    "card_sequence_no": string,
    "payment_type": string,
    "pan": string,
    "terminal_id": string,
    "aid": string,
    "cc": string,
    "receipt_no": string,
    "card_type": string,
    "card_type_id": string,
    "aidpar": string,
    "vunumber": string,
    "result_code_as": string,
    "turnover_no": string,
    "card_name": string,
    "error_code": string,
    "error_text": string,
    "sys_timestamp": Date
}

export enum MachineState {
    READY = "ready",
    PAYING = "paying",
    DISABLED = "disabled"
}

export interface MachineData {
    dummy?: boolean,

    buttonLabel: string,
    installationDate?: dayjs.Dayjs
    price: number,
    happyHourPrice?: number,
    disabled: boolean
    rs485id?: number

    configName: string
    manufacturer: string
    type: WtgType
    maxTimeAddition: number

    state?: MachineState
    connected?: boolean
}

export interface MachineTypeData {
    id?: number
    brand: string
    modelNumber: string
    shortLabel: string
    type: WtgType
    iconId: number
    rs485: boolean
    additionalTime?: number
    controlTypeTimeControl?: boolean
    impulseTimeMs?: number
    waitTimeMs?: number
    switchTimeSec?: number
    lockTimeSec: number
}

export interface MachineTypeDataWithReferenceCount extends MachineTypeData {
    numberOfReferences: number
    defaultConfig: boolean
}

export interface User {
    name: string;
    permissions: UserPermission[];
}

export enum UserPermission {
    // Roles
    ROLE_ADMIN = "ROLE_Administrator",  // Kann alles
    ROLE_ECO = "ROLE_EcoExpress",       // Kann alles außer Benutzer editieren
    ROLE_OPERATOR = "ROLE_Betreiber",   // Sieht und steuert eigene GWAs
    ROLE_CALLCENTER = "ROLE_Callcenter",// Sieht und steuert alle GWAs
    ROLE_JANITOR = "ROLE_Hausmeister",  // Sieht und steuert eigene GWAs
    ROLE_SM_ADMIN = "ROLE_S+M-Admin",   // Sieht und steuert alle WS (Stammdatensystem nur lesend)

    // Single permissions
    SHOW_ALL_GWAS = "SHOW_ALL_GWAS",            // Hauptseite, unnötig fürs FE
    SHOW_OWN_GWAS = "SHOW_OWN_GWAS",            // Hauptseite, unnötig fürs FE
    EDIT_GWAS = "EDIT_GWAS",                    // Kontakte, Standort, BetreiberID
    EDIT_DEVICES = "EDIT_DEVICES",              // Kotroll Panel, wie implementiert
    EDIT_USERS = "EDIT_USERS",                  // User hinzufügen, löschen
    EDIT_PRICES = "EDIT_PRICES",                // Ist klar
    EDIT_MCS = "EDIT_MCS",                      // Vllt später
    EDIT_OPERATORS = "EDIT_OPERATORS",          // Kann Betreiber hinzufügen, editieren und löschen
    START_OTA = "START_OTA",                    // Updatefunktion
    CONTROL_DEVICES = "CONTROL_DEVICES",        // Schon im Kontroll Panel
    SHOW_ALL_USERS = "SHOW_ALL_USERS",          // Benötigt für Stammdaten
    EDIT_WTG = "EDIT_WTG",                      // Kann WTGs hinzufügen, editieren und löschen
    EDIT_CASH = "EDIT_CASH",                    // Kann Bargeldsteuerung editieren
    DOWNLOAD_SERVER_LOG = "DOWNLOAD_SERVER_LOG" // Kann Server-Log herunterladen
}

export enum PermissionCheck {
    ALL, ANY
}

interface BackRouteConfig {
    path: string
    label: string
}

export interface RouteConfig {
    path: string,
    requiredPermissions: UserPermission[]
    permissionCheck: PermissionCheck
    onNavbar: boolean
    label?: string
    component: React.ReactNode
    backRoute?: BackRouteConfig
}

export interface Operator {
    id?: number
    companyName: string
    postal: string
    city: string
    streetNr: string
    iban: string
    bic: string
    note: string
    contactId: number
    comment: string
}

export interface ESPConfig {
    id?: number,
    label: string,
    ipProductiveSystem: string,
    ipEmergencySystem?: string,
    ipTestSystem?: string,
    ipOTADownload?: string,
    ptStatusEnquiryTime: number,
    ptReconnectTime: number,
    username: string,
    password?: string
}

export interface ESPConfigWithReferenceCount extends ESPConfig {
    numberOfReferences: number
}

export enum SystemConfigTab {
    COMMERCIAL_UNIT = "Betriebsstätte",
    COMMERCIAL_UNIT_LOCATION = "Lokation",
    COMMERCIAL_UNIT_BASE_CONFIG = "Basiskonfiguration",
    COMMERCIAL_UNIT_MACHINE_PANEL = "Maschinen-Panel",
    COMMERCIAL_UNIT_TIME_CONFIG = "Zeitkonfiguration",
    COMMERCIAL_UNIT_COIN_CONFIG = "Münzerkonfiguration",
    COMMERCIAL_UNIT_SYSTEM_CONTROL = "Systemsteuerung",
    OPERATOR = "Betreiber",
    PERSON = "Person",
    PAY_TERMINAL = "Zahlautomat",
    ESP_CONFIGURATION = "ESP Konfiguration",
    MACHINES = "Maschinen"
}

export enum CommercialUnitStage {
    COMMERCIAL_UNIT_LOCATION = "Lokation",
    COMMERCIAL_UNIT_BASE_CONFIG = "Basiskonfiguration",
}

export interface PTConfig {
    id?: number
    espUid: number
    establishmentId?: number
    espConfigName?: string
    heartbeatIntervalSeconds: number
    shoppingBasketTimeoutSeconds?: number
    testMode: boolean
    timeControl: boolean
    circulatorPumpTimeSeconds?: number
    webSocketDisplay: boolean
    paymentProtocolZvt: boolean
    twoLines?: boolean
    vencube?: boolean
    cash?: boolean
    banknote?: boolean
    cashless: boolean
    hwVersion: string;
    swVersion: string;
    cardReaderManufacturer: string;
    cardReaderType: string;
    cardReaderId: string;
    venCubeNumber: string;
    provider: string;
}

export interface Establishment {
    id?: number;
    operator?: Operator // This makes operators unselectable in the form!
    janitor?: Contact
    type: LocationType
    streetAndNumber: string
    additionToAddress?: string
    postalCode: string
    location: string
    roomInformation?: string
    note?: string
    paymentMachines: PTConfig[]
}

export enum LocationType {
    GWA = "Gemeinschaftswaschanlage",
    LAUNDROMAT = "Waschsalon"
}

export enum WtgType {
    WASCHMASCHINE = "Waschmaschine",
    TROCKNER = "Trockner",
    WASCHMITTELAUSGABE = "Waschmittelausgabe"
}

export enum WtgTypeShort {
    WASCHMASCHINE = "Waschen",
    TROCKNER = "Trocknen",
    WASCHMITTELAUSGABE = "Waschmittel"
}

const enumMapping = new Map<WtgType, WtgTypeShort>([
    [WtgType.WASCHMASCHINE, WtgTypeShort.WASCHMASCHINE],
    [WtgType.TROCKNER, WtgTypeShort.TROCKNER],
    [WtgType.WASCHMITTELAUSGABE, WtgTypeShort.WASCHMITTELAUSGABE]
]);

export function toWtgTypeShort(type: WtgType) {
    return enumMapping.get(type);
}
