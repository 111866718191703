import {en} from "./en";
import {de} from "./de";

export type SupportedLanguages = "en" | "de";

export interface Literals {
    lang: string,
    welcome: string;
    summary: SummaryLiterals;
    washStationDetails: {
        notAvailable: string,
        log: LogTitles
    };
    footer: FooterLiterals;
    navBar: {
        logout: string,
        language: string
    }
}

export interface LogTitles {
    sysTime: string,
    paymentTime: string,
    amount: string,
    resultText: string,
    receiptNo: string,
    paymentType: string,
    cardNo: string,
    expirityDate: string,
    cardName: string,
    products: string,
    terminalId: string
}

export interface FooterLiterals {
    privacy: string,
    legal: string
}

export type Languages = {
    [key in SupportedLanguages]: Literals;
};

export const lang: Languages = {
    en,
    de,
}

export interface SummaryLiterals {
    type: string,
    address: string,
    dailyRevenue: string,
    monthlyRevenue: string,
    yearlyRevenue: string,
    lastUpdate: string
}
