import {Literals} from "./models";

export const en: Literals = {
    lang: "English",
    welcome: "Welcome",
    summary: {
        type: "Type",
        address: "Address",
        dailyRevenue: "Daily Revenue",
        monthlyRevenue: "Monthly Revenue",
        yearlyRevenue: "Yearly Revenue",
        lastUpdate: "Last update..."
    },
    washStationDetails: {
        notAvailable: "Not Available",
        log: {
            sysTime: "System Time",
            paymentTime: "Payment Time",
            amount: "Price",
            resultText: "Result Code",
            receiptNo: "Receipt No",
            paymentType: "Payment Type",
            cardNo: "CardNo",
            expirityDate: "Expiry Date",
            cardName: "Card Name",
            products: "Products",
            terminalId: "ID (Reader)"
        }
    },
    footer: {
        privacy: "Privacy Policy",
        legal: "Legal"
    },
    navBar: {
        logout: "Logout",
        language: "Sprache"
    }
};
