import {Literals} from "./models";

export const de: Literals = {
    lang: "Deutsch",
    welcome: "Willkommen",
    summary: {
        type: "Typ",
        address: "Adresse",
        dailyRevenue: "Tagesumsatz",
        monthlyRevenue: "Monatsumsatz",
        yearlyRevenue: "Jahresumsatz",
        lastUpdate: "letzte Transaktion"
    },
    washStationDetails: {
        notAvailable: "Nicht verfügbar",
        log: {
            sysTime: "Systemzeit",
            paymentTime: "Zahlungszeit",
            amount: "Preis",
            resultText: "Bezahlstatus",
            receiptNo: "Receipt No",
            paymentType: "Bezahlart",
            cardNo: "Kartennummer",
            expirityDate: "Gültig bis",
            cardName: "Kartenname",
            products: "Produkte",
            terminalId: "PT-ID (Leser)"
        }
    },
    footer: {
        privacy: "Datenschutzerklärung",
        legal: "Impressum"
    },
    navBar: {
        logout: "Logout",
        language: "Sprache"
    }
};
