import React, {useEffect} from "react";
import {connect} from "react-redux";
import {RootState} from "../../store/root-reducer";
import {getHeartbeatInterval} from "../../store/heartbeatInterval/HeartbeatSelector";
import {HeartbeatInterval} from "../../store/heartbeatInterval/HeartbeatReducer";
import {notifyCustom} from "./Notifications";
import {CountdownCircle} from "./CountdownCircle";
import "./heartbeat-container.css"

interface Props {
    heartbeatInterval: HeartbeatInterval | null
}

export const HeartbeatNotificationComponent = (props: Props) => {

    const createNotificationContainer = (difference: number, text: string) => {
        return <div className={"heartbeat-notification-container"}>
            <CountdownCircle duration={difference}/>
            <div className={"description-container"}>
                {text}
            </div>
        </div>;
    };

    useEffect(() => {
        const heartbeatInterval = props.heartbeatInterval;
        if (heartbeatInterval && isTimestampAheadOfTime(heartbeatInterval)) {
            const endTimestamp = new Date(heartbeatInterval.endTimestamp)
            const difference = endTimestamp.getTime() - Date.now();
            notifyCustom(createNotificationContainer(difference, heartbeatInterval.description));
        }
    }, [props])

    return <></>;
}

// liegt das Ende in der Zukunft?
export const isTimestampAheadOfTime = (heartbeatInterval: HeartbeatInterval) => {
    if (heartbeatInterval) {
        return new Date() < heartbeatInterval.endTimestamp;
    }
    return false
}

const mapStateToProps = (state: RootState) => {
    return {
        heartbeatInterval: getHeartbeatInterval(state.interval)
    }
}

export const HeartbeatNotification = connect(mapStateToProps)(HeartbeatNotificationComponent);
