import {Component} from 'react';
import styled from 'styled-components';
import eventEmitter from "event-emitter"
import {hyphenationFormatter} from "../Table/TableFormatter";

interface State {
    top: number,
    msg: string,
    type: NotificationType,
    component?: JSX.Element
}

export enum NotificationType {
    BASIC = "basic",
    WARNING = "warning"
}

// @ts-ignore
function getContainerTop(props) {
    // Typescript workaround
    return props.top;
}

const Container = styled.div`
    color: white;
    padding: 16px;
    position: fixed;
    top: ${props => getContainerTop(props)}px;
    right: 16px;
    z-index: 9999;
    transition: top 0.5s ease 0s;
    border-radius: .25rem;
    max-width: 50%;
`;

const emitter = eventEmitter();

export const notify = (msg: string, notificationType?: NotificationType) => {
    console.debug("EMIT NOTIFY")
    emitter.emit('notification', {msg, notificationType: notificationType ?? NotificationType.BASIC})
}

export const notifyCustom = (component: JSX.Element, notificationType?: NotificationType) => {
    console.debug("EMIT NOTIFY CUSTOM")
    emitter.emit('customNotification', {component, notificationType: notificationType ?? NotificationType.BASIC})
}

export const hideNotification = () => {
    console.debug("EMIT HIDE NOTIFICATION")
    emitter.emit('hideNotification')
}

export default class Notifications extends Component<{}, State> {
    timeout?: ReturnType<typeof setTimeout>;

    constructor(props: {}) {
        super(props);

        this.state = {
            top: -this.getNotificationHeight() - 50,
            msg: '',
            type: NotificationType.BASIC,
            component: undefined
        }
        this.timeout = undefined;
        emitter.on('notification', msg => {
            this.setState({component: undefined, type: msg.notificationType})
            this.onShow(msg.msg)
        });

        emitter.on('customNotification', msg => {
            this.setState({component: msg.component, type: msg.notificationType})
            this.onShowCustom()
        });

        emitter.on('hideNotification', () => {
            this.onHide()
        });
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            // wenn versteckt
            if (this.state.top < 0) {
                this.onHide()
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => {
        })
    }

    onShowCustom = () => {
        this.showNotificationBasic()
    }

    onHide = () => {
        console.debug("ON HIDE")
        console.debug(this.state.msg)
        this.setState({top: -this.getNotificationHeight() - 50});
        this.timeout = undefined
    }

    onShow = (msg: string) => {
        if (this.timeout && msg) {
            clearTimeout(this.timeout);
            this.setState({top: -this.getNotificationHeight() - 50}, () => {
                this.timeout = setTimeout(() => {
                    this.showNotification(msg);
                    this.timeout = setTimeout(() => {
                        this.onHide()
                    }, 6000)
                }, 200)
            })
        } else {
            this.showNotification(msg, () => {
                this.timeout = setTimeout(() => {
                    this.onHide()
                }, 6000)
            })
        }
    }

    showNotification = (msg: string, fct?: () => void) => {
        this.setState({
            top: 10,
            msg
        }, fct);
    }

    showNotificationBasic = () => {
        if (this.timeout) {
            clearTimeout(this.timeout)
        }
        this.showNotification("", () => {
            console.debug(this.state)
        });
    }

    getNotificationContent = () => {
        return this.state.component ?? hyphenationFormatter(this.state.msg);
    }

    getNotificationHeight() {
        const toast = document.getElementsByClassName("custom-toast")[0];
        return toast?.clientHeight ?? 50;
    }

    render() {
        console.debug(this.state)
        return (
            /* @ts-ignore */
            <Container className={`custom-toast ${this.state.type}`} top={this.state.top}>
                {this.getNotificationContent()}
            </Container>
        )
    }
}
