import {applyMiddleware, createStore, Middleware} from 'redux';
import thunk from 'redux-thunk'
import rootReducer, {RootState} from "./root-reducer";
import {isTimestampAheadOfTime} from "../Components/Overlays/HeartbeatNotification";

const ConfigureStore = () => {
    let initialState = {};
    try {
        const masterClass = sessionStorage.getItem("master_class");
        initialState = masterClass ? JSON.parse(masterClass) : {};
    } catch (error) {
        console.log('getError', error)
    }
    const saver: Middleware<object, RootState> = (reduxStore) => next => action => {
        const rootState = reduxStore.getState();

        // @ts-ignore
        const isIntervalInFuture = isTimestampAheadOfTime(rootState.interval)
        const intervalToSave = isIntervalInFuture ? {interval: rootState.interval} : {};
        const stateToSave = {user: rootState.user};
        sessionStorage.setItem("master_class", JSON.stringify({...stateToSave, ...intervalToSave}))
        return next(action);
    }

    return createStore(rootReducer, initialState, applyMiddleware(thunk, saver));
}

const store = ConfigureStore();
export default store;
