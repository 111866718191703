import {UserState} from "./UserReducer";
import {User} from "../../models/models";

export const isAuthenticated = (state: UserState) => {
    return state.user !== null;
}

export const getUser = (state: UserState): User | null => {
    return state.user;
}

export const getPermissions = (state: UserState) => {
    return state.user?.permissions ?? [];
}
