import React, {Component} from "react"
import {RouteComponentProps, withRouter} from "react-router";
import {Link, NavLink} from "react-router-dom"
import {Nav, Navbar} from "react-bootstrap";
import {connect} from "react-redux";
import {PermissionCheck, UserPermission} from "../../models/models";
import getRouteConfigs from "../../data/getRouteConfigs";
import {getPermissions, isAuthenticated} from "../../store/user/UserSelector";
import {RootState} from "../../store/root-reducer";
import {logout} from "../../store/user/UserActions";
import {pathEquals} from "../../utils/StringUtils";

import "../bootstrap.scss"

interface ComponentProps {
    backupMode?: boolean
}

interface Properties extends ComponentProps, RouteComponentProps {
    permissions: UserPermission[];
    loggedIn: boolean;

    logout(): void;
}

class NavBarComponent extends Component<Properties> {

    getNavigationFromConfig() {
        const grantedPermissions = this.props.permissions
        const headerRoutes = getRouteConfigs()
            .filter(route => route.onNavbar)
            .filter(route => hasPermission(
                route.requiredPermissions, grantedPermissions, route.permissionCheck
            ))
            .map(r => {
                if (r.backRoute !== undefined) {
                    const location = this.props.location.pathname;
                    if (pathEquals(location, r.path)) {
                        return {
                            ...r,
                            label: r.backRoute.label,
                            path: r.backRoute.path
                        };
                    }
                }
                return r;
            });
        return headerRoutes.map(route => <NavLink id={route.label}
                                                  key={`NavLink_${route.path}`}
                                                  to={route.path}
                                                  className={"nav-link"}>
            {route.label}
        </NavLink>)
    }

    getLogout() {
        if (!this.props.loggedIn) {
            return <></>
        }
        return <Nav.Link id={"logout"} onClick={() => {
            this.props.logout()
        }}>Logout</Nav.Link>
    }

    getTitle() {
        if (window.innerWidth <= 730) {
            return <></>;
        }
        return <h1 style={{padding: "unset", borderBottom: "unset", marginLeft: "2%"}}>
            {!this.props.backupMode ?
                "Management System" :
                <span style={{color: "red"}}>Backup System - NUR ZUR WIEDERHERSTELLUNG</span>
            }
        </h1>;
    }

    render() {
        return (
            <Navbar bg="light" expand="lg" sticky="top">
                <div className="nav-content-wrapper">
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav id="nav-left">
                            <Link to={"/"}>
                                <div className={"home-icon"}>
                                    <img src={"/static/img/logo-eco-express.png"} alt="home-icon"/>
                                </div>
                            </Link>
                            {this.getTitle()}
                        </Nav>
                        <Nav id="nav-right">
                            {this.getNavigationFromConfig()}
                            {this.getLogout()}
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        );
    }
}

export const hasPermission = (requiredPermissions: UserPermission[],
                              permissionsGranted: UserPermission[], permissionCheck: PermissionCheck) => {
    if (!requiredPermissions || !requiredPermissions.length) {
        // Nothing required
        return true;
    }

    if (permissionCheck === PermissionCheck.ANY) {
        return requiredPermissions.some(perm => permissionsGranted.includes(perm));
    } else if (permissionCheck === PermissionCheck.ALL) {
        return requiredPermissions.every(perm => permissionsGranted.includes(perm));
    }
    throw new Error("Unknown PermissionCheck type!");
}

const mapStateToProps = (state: RootState, ownProps: ComponentProps) => {
    return {
        ...ownProps,
        permissions: getPermissions(state.user),
        loggedIn: isAuthenticated(state.user)
    }
};

export const NavBar = connect(mapStateToProps, {logout})(withRouter(NavBarComponent));
