import React, {lazy} from 'react';
import {RouteComponentProps, StaticContext, withRouter} from "react-router";
import AbstractHistoryView from "./AbstractHistoryView";

const Summary = lazy(() => import("./FleetManagement/Summary"));
const WashStationDetails = lazy(() => import( "./FleetManagement/WashStationDetails"));

enum SummaryContainerLayer {
    SUMMARY = "Summary",
    WASH_STATION_DETAILS = "WashStationDetails"
}

interface HomeLocationState {
    layer: SummaryContainerLayer,
    locationId: number
}

type Properties = RouteComponentProps<{}, StaticContext, HomeLocationState>;

class HomeComponent extends AbstractHistoryView<HomeLocationState> {

    getDefaultHistoryLocationState(): Readonly<HomeLocationState> {
        return Object.freeze({
            layer: SummaryContainerLayer.SUMMARY,
            locationId: 0
        });
    }

    isHistoryLocationState(object: any): object is HomeLocationState {
        return object && 'layer' in object && 'locationId' in object;
    }

    shouldComponentUpdate(_nextProps: Readonly<Properties>, nextState: Readonly<HomeLocationState>) {
        if (!nextState) {
            return true;
        }
        return this.state.layer !== nextState.layer || this.state.locationId !== nextState.locationId
    }

    renderViewSummary() {
        return (
            <Summary
                onSummaryElementClicked={locationId => {
                    this.changeLayer(SummaryContainerLayer.WASH_STATION_DETAILS, locationId)
                }}
            />
        );
    }

    renderViewWashStationDetails(locationId: number) {
        return (
            <WashStationDetails locationId={locationId}/>
        )
    }

    changeLayer(newLayer: SummaryContainerLayer, locationId: number) {
        this.pushToHistory({
            layer: newLayer,
            locationId: locationId
        }, "/home", () => this.state);
    }

    render() {
        //Render layer
        let layer;
        if (this.state.layer === SummaryContainerLayer.SUMMARY) {
            layer = this.renderViewSummary();
        } else if (this.state.layer === SummaryContainerLayer.WASH_STATION_DETAILS) {
            layer = this.renderViewWashStationDetails(this.state.locationId);
        }

        return <div className="wash-station-frame">
            {layer}
        </div>;
    }
}

const Home = withRouter(HomeComponent);
export default Home;
