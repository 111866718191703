import {configureRefreshFetch} from "refresh-fetch";

export class FetchError extends Error {
    readonly response: Response;

    constructor(response: Response) {
        super(`Error during fetch of url ${response.url}! Code ${response.status}: ${response.statusText}`);
        this.response = response;
    }
}

function throwOnResponseErrorFetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    return fetch(input, init)
        .then(res => {
            if (!res.ok) {
                throw new FetchError(res);
            }
            return res;
        });
}

export const refreshFetch = configureRefreshFetch({
    // Pass fetch function you want to wrap, it should already be adding
    // token to the request
    fetch: throwOnResponseErrorFetch,

    // shouldRefreshToken is called when API fetch fails and it should decide
    // whether the response error means we need to refresh token
    shouldRefreshToken: (error: any) => {
        if (error instanceof FetchError) {
            const response = error.response;
            return response.status === 401;
        }

        // AbortController causes a DOMException to be thrown
        return false;
    },

    // refreshToken should call the refresh token API, save the refreshed
    // token and return promise -- resolving it when everything goes fine,
    // rejecting it when refreshing fails for some reason
    refreshToken: () => {
        return throwOnResponseErrorFetch("/jwt/refresh", {
            method: "POST"
        }).then(() => {
            // NOOP to convert Promise<Response> to Promise<void>
        });
    }
})
