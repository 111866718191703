import {combineReducers} from 'redux';
import LiteralsReducer from "./literals/LiteralsReducer";
import {StateType} from 'typesafe-actions';
import ConfigReducer from "./config/ConfigReducer";
import UserReducer from "./user/UserReducer";
import HeartbeatReducer from "./heartbeatInterval/HeartbeatReducer";

const rootReducer = combineReducers({
    literals: LiteralsReducer,
    config: ConfigReducer,
    user: UserReducer,
    interval: HeartbeatReducer
});

export default rootReducer;
export type RootState = StateType<typeof rootReducer>;
